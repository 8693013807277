import { ReactElement, useState } from 'react';

import * as z from 'zod';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProvider';
import { useDependentCountStore } from '../../../../stores/useDependentCountStore';
import { useHirePlanFormStepStore } from '../../../../stores/useHirePlanFormStepStore';
import { Button } from '../../../Button';
import { PlusIcon } from '../../../icons/PlusIcon';
import { dependentsSchema } from '../../data/schema/dependentsSchema';
import { GoForwardButton } from '../../GoForwardButton';
import { DependentCard } from './DependentCard';
import { DependentForm } from './DependentForm';

function validateDependent(obj: any): boolean {
  try {
    dependentsSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

export function DependentsForm(): ReactElement {
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);

  const { watch, setValue, errors, handleSubmit, goToNextForm } = useHirePlanForm();

  const [isDependentFormActive, setIsDependentFormActive] = useState(false);

  function activateDependentForm() {
    setIsDependentFormActive(true);
  }

  function closeDependentForm() {
    setIsDependentFormActive(false);
  }

  const dependentCount = useDependentCountStore(state => state.dependentCount);

  const dependents = watch('user.dependents');

  function setOnlyValidatedDependents() {
    setValue(
      'user.dependents',
      dependents?.filter(dependent => validateDependent(dependent)),
    );
  }

  function handleSubmitt() {
    setOnlyValidatedDependents();
    handleSubmit(goToNextForm)();
  }

  return (
    <div className="max-w-[755px] w-full flex flex-col items-center">
      <div className="max-[748px]:hidden flex flex-col items-center">
        <h2 className="min-[748px]:text-[2rem] text-2xl font-semibold text-primary-main">
          Dependentes
        </h2>
        <p className="font-normal text-secondary-main mt-1">
          Você pode adicionar quantos dependentes desejar.
        </p>
      </div>
      {!isDependentFormActive && (
        <Button
          text="Adicionar dependente"
          intent="secondary"
          Icon={PlusIcon}
          className="mt-16"
          onClick={activateDependentForm}
        />
      )}
      {isDependentFormActive && <DependentForm onSave={closeDependentForm} />}
      <div className="mt-10">
        {dependents?.map((dependent, index) => {
          if (validateDependent(dependent) && index < dependentCount) {
            return (
              <DependentCard
                index={index}
                dependent={dependent as any}
                key={dependent.name}
              />
            );
          }
          return;
        })}
      </div>

      <GoForwardButton addBackButton onForwardClick={handleSubmitt} />
    </div>
  );
}
