import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../contexts/HirePlanFormProvider';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { GoForwardButton } from '../GoForwardButton';
import { useRouteLoaderData } from 'react-router-dom';
import { validateCEP } from '../../../utils/ValidateCEP';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeUF,
} from '../../../utils/ChangeArrays';
import {
  IndividualWithShortageNorthEastTokens,
  IndividualWithShortageTokens,
  IndividualWithoutShortageTokens,
} from '../../../utils/Enums/Tokens';
import { usePlanLoadingStore } from '../../../stores/usePlanLoadingStore';
import { validateCPF } from '../../../utils/ValidateCPF';
import { getPlanDetails } from '../../../requests/requests';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import Loading from '../../icons/Loading';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';

export function BeneficiaryRegisterForm(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors, watch, setValue } =
    useHirePlanForm();
  const initialPlanData: any = useRouteLoaderData('token');
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);

  const setNortheastPlanData = useNortheastPlanDataStore(
    state => state.setNortheastPlanData,
  );
  const setLoading = usePlanLoadingStore(state => state.setLoading);
  const boostPlan = useBoostedPlanDataStore(x => x.data);
  const setBoostPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );
  const CEP = watch('user.address.cep');
  const CPF = watch('user.documents.cpf');
  const firstMothersName = watch('user.firstMothersName');
  const UF = watch('user.address.uf');
  const city = watch('user.address.city');
  const neighborhood = watch('user.address.neighborhood');
  const street = watch('user.address.street');

  const [data, setData] = useState(initialPlanData);
  const [newData, setnewData] = useState({});

  async function handleCEPNorthEastChange() {
    let newToken: string | undefined = undefined;
    switch (data.token) {
      case IndividualWithShortageNorthEastTokens.CLINICO:
      case IndividualWithShortageTokens.CLINICO: {
        newToken = IndividualWithShortageNorthEastTokens.CLINICO;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 1 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageNorthEastTokens.ORTO:
      case IndividualWithShortageTokens.ORTO: {
        newToken = IndividualWithShortageNorthEastTokens.ORTO;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 1 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageNorthEastTokens.DOC:
      case IndividualWithShortageTokens.DOC: {
        newToken = IndividualWithShortageNorthEastTokens.DOC;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 1 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageNorthEastTokens.PROTESE:
      case IndividualWithShortageTokens.PROTESE: {
        newToken = IndividualWithShortageNorthEastTokens.PROTESE;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 1 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageNorthEastTokens.KIDS:
      case IndividualWithShortageTokens.KIDS: {
        newToken = IndividualWithShortageNorthEastTokens.KIDS;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 1 });
        setBoostPlanData(response);
        break;
      }
    }
  }

  async function handleCEPChange(gp: boolean) {
    let newToken: string | undefined = undefined;
    switch (data.token) {
      case IndividualWithShortageNorthEastTokens.CLINICO:
      case IndividualWithShortageTokens.CLINICO:
      case IndividualWithShortageTokens.CLINICONA: {
        if (gp) {
          newToken = IndividualWithShortageTokens.CLINICO;
        } else {
          newToken = IndividualWithShortageTokens.CLINICONA;
        }
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 0 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageTokens.ORTO:
      case IndividualWithShortageNorthEastTokens.ORTO: {
        newToken = IndividualWithShortageTokens.ORTO;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 0 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageTokens.DOC:
      case IndividualWithShortageNorthEastTokens.DOC: {
        newToken = IndividualWithShortageTokens.DOC;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 0 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageTokens.PROTESE:
      case IndividualWithShortageNorthEastTokens.PROTESE: {
        newToken = IndividualWithShortageTokens.PROTESE;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 0 });
        setBoostPlanData(response);
        break;
      }
      case IndividualWithShortageTokens.KIDS:
      case IndividualWithShortageNorthEastTokens.KIDS: {
        newToken = IndividualWithShortageTokens.KIDS;
        const response = await getPlanDetails(newToken);
        setnewData(response);
        setNortheastPlanData({ ...response, northeast: 0 });
        setBoostPlanData(response);
        break;
      }
    }
  }

  const getCEPDetails = async (e: string) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());
    console.log('cep inform: ', CEPInformations);
    const nordeste = ['AL', 'PB', 'PE'];
    const gp = [
      'SE',
      'RN',
      'PI',
      'PA',
      'MT',
      'MS',
      'MA',
      'CE',
      'BA',
      'AM',
      'TO',
      'GO',
      'DF',
    ];
    setLoading(false);
    const grupoEstados = gp.includes(CEPInformations.uf);
    if (nordeste.includes(CEPInformations.uf)) {
      handleCEPNorthEastChange();
    } else {
      handleCEPChange(grupoEstados);
    }

    if (CEPInformations.status === 500 || CEPInformations.status === 404) {
      throw new Response('Not Found', { status: 404 });
    } else if (CEPInformations.erro) {
      setLoading(true);
    }

    const UF = data.listaUFS.find(x => x.sigla == CEPInformations.uf).Id;
    setValue('user.address.city', CEPInformations.localidade);
    setValue('user.address.neighborhood', CEPInformations.bairro);
    setValue('user.address.street', CEPInformations.logradouro);
    setValue('user.address.uf', String(UF));

    return CEPInformations;
  };

  const getCPFDetails = async (cpf: string) => {
    await fetch(
      `${process.env.REACT_APP_OG_API_URL}/assertiva/getAssertivaInfo/${cpf}`,
    )
      .then(response => response.json())
      .then(dados => {
        if (dados.nome) {
          setValue('user.fullName', dados.nome);
          setValue('user.birthDate', dados.dataNascimento);
          setValue('user.fullMothersName', dados.maeNome);

          return dados;
        }
      });
  };

  useEffect(() => {
    console.log('newData: ', newData);
    console.log('boostPlan: ', boostPlan);
    if (Object.keys(newData).length !== 0 && newData != data) {
      setData(newData);
    }
  }, [newData]);

  useEffect(() => {
    if (CEP && validateCEP.test(CEP)) {
      getCEPDetails(CEP);
    }
  }, [CEP]);

  useEffect(() => {
    if (CPF && validateCPF.test(CPF)) {
      getCPFDetails(CPF);
    }
  }, [CPF]);

  useEffect(() => {
    if (Object.keys(northeastPlanData).length === 0) {
      setNortheastPlanData({ ...initialPlanData });
    }
    setLoading(true);
  }, []);

  changeUF(data.listaUFS);
  changeGender(data.listaSexos);
  changeIssuingAgency(data.listaOrgaosExpedidor);
  changeCivilStatus(data.listaEstadosCivil);
  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Dados Iniciais
      </h2>

      <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
        <Input
          activateMask
          mask="99999-999"
          label="CEP"
          className="w-full"
          //onBlur={e => getPlanValueByCEP(e.target.value)}
          placeholder="CEP"
          errorMessage={errors.user?.address?.cep?.message}
          {...register('user.address.cep')}
        />
        <Input
          label="CPF"
          placeholder="CPF"
          activateMask
          mask="999.999.999-99"
          errorMessage={errors.user?.documents?.cpf?.message}
          {...register('user.documents.cpf')}
        />
        <Input
          activateMask
          mask="99/99/9999"
          label="Data de nascimento"
          placeholder="Data de nascimento"
          errorMessage={errors.user?.birthDate?.message}
          {...register('user.birthDate')}
        />
        <Input
          label="Primeiro nome da mãe"
          placeholder="Primeiro nome da mãe"
          errorMessage={errors.user?.firstMothersName?.message}
          {...register('user.firstMothersName')}
        />
        <Input
          label="E-mail"
          placeholder="E-mail"
          errorMessage={errors.user?.email?.message}
          {...register('user.email')}
        />
        <Input
          label="Telefone"
          activateMask
          mask="(99) 99999-9999"
          placeholder="Telefone"
          errorMessage={errors.user?.phone?.message}
          {...register('user.phone')}
        />
      </div>
      {/* <div className="p-6 min-[748px]:text-xl text-lg text-primary-main flex flex-col justify-center items-center mt-12 bg-[#F8F8FB]">
        <h3 className="text-center">Enviamos um token por sms para você</h3>
        <div className="mt-8">
          <Input
            activateMask
            label=""
            mask="9 . 9 . 9 . 9 . 9 . 9"
            //onChange={ola}
            placeholder="0 . 0 . 0 . 0 . 0 . 0"
            className="text-center"
            errorMessage={errors.user?.token?.message}
            {...register('user.token')}
          />
          <div className="flex justify-center">
            <button className="block mt-1 text-xs underline text-primary-main text-center">
              Reenviar código por sms
            </button>
          </div>
        </div>
      </div> */}
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8 mt-16">
        Dados do beneficiário
      </h2>
      <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-6">
        <div className="grid grid-cols-1 gap-8">
          <Input
            label="Nome completo"
            placeholder="Nome completo"
            errorMessage={errors.user?.fullName?.message}
            {...register('user.fullName')}
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Input
            label="RG"
            placeholder="RG"
            //type="number"
            errorMessage={errors.user?.documents?.rg?.message}
            {...register('user.documents.rg')}
          />
          <Select
            label="Órgão expeditor"
            options={[]}
            optionsFull={data.listaOrgaosExpedidor}
            placeholder="Órgão expeditor"
            errorMessage={errors.user?.documents?.issuingAgency?.message}
            {...register('user.documents.issuingAgency')}
          />
          <Select
            label="UF órgão expedidor"
            options={[]}
            optionsFull={data.listaUFS}
            placeholder="Órgão expeditor UF"
            errorMessage={errors.user?.documents?.ufIssuingAgency?.message}
            {...register('user.documents.ufIssuingAgency')}
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Input
            label="CNS- Opcional"
            placeholder="CNS"
            errorMessage={errors.user?.documents?.cns?.message}
            {...register('user.documents.cns')}
          />
          <Select
            label="Gênero"
            options={[]}
            optionsFull={data.listaSexos}
            placeholder="Gênero"
            errorMessage={errors.user?.gender?.message}
            {...register('user.gender')}
          />
          <Select
            label="Estado civil"
            options={[]}
            optionsFull={data.listaEstadosCivil}
            placeholder="Estado civil"
            errorMessage={errors.user?.civilStatus?.message}
            {...register('user.civilStatus')}
          />
        </div>
        <Input
          label="Nome da mãe completo"
          placeholder="Nome da mãe completo"
          errorMessage={errors.user?.fullMothersName?.message}
          {...register('user.fullMothersName')}
        />
        <Input
          label="Logradouro"
          value={street}
          placeholder="Digite o seu logradouro"
          errorMessage={errors.user?.address?.street?.message}
          {...register('user.address.street')}
        />
        <div className="grid grid-cols-2 gap-8">
          <Input
            label="Complemento"
            placeholder="Digite o seu complemento"
            errorMessage={errors.user?.address?.complement?.message}
            {...register('user.address.complement')}
          />
          <Input
            label="Número"
            //type="number"
            placeholder="Digite o seu número"
            errorMessage={errors.user?.address?.number?.message}
            {...register('user.address.number')}
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Input
            label="Bairro"
            value={neighborhood}
            placeholder="Digite o seu bairro"
            errorMessage={errors.user?.address?.neighborhood?.message}
            {...register('user.address.neighborhood')}
          />
          <Input
            label="Cidade"
            value={city}
            placeholder="Digite sua cidade"
            errorMessage={errors.user?.address?.city?.message}
            {...register('user.address.city')}
          />
          <Select
            label="UF"
            options={[]}
            //options={data.listaUFS.map(uf => uf.sigla)}
            optionsFull={data.listaUFS}
            //value={UF}
            //onChange={{...register('payment.expireDate')}}
            placeholder="UF"
            errorMessage={errors.user?.address?.uf?.message}
            {...register('user.address.uf')}
          />
        </div>
      </div>
      <GoForwardButton />
    </form>
  );
}
