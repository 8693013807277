import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProviderGdf';
import { Input } from '../../../Input';
import { GoForwardButton } from '../GoForwardButton';
import { useRouteLoaderData } from 'react-router-dom';
import { validateCPF } from '../../../../utils/ValidateCPF';

export function ServidorValidationForm(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors, watch, setValue } =
    useHirePlanForm();
  const initialPlanData: any = useRouteLoaderData('token-servidor');

  const [error, setError] = useState<string | null>(null);

  const Matricula = watch('valida.validation.matricula');
  const CPF = watch('valida.validation.cpf');

  const [data, setData] = useState(initialPlanData);

  const [showError, setShowError] = useState(false);

  const validaDados = async () => {
    if (!CPF || !Matricula) {
      handleSubmit(goToNextForm)();
      return false;
    }

    await getCPFDetails(CPF, Matricula);

    const Validation = watch('valida.validation.retorno');

    if (!Validation) {
      setError(
        'As informações não são suficientes para sua identificação como servidor do GDF;\nEntre em contato conosco pelo 40072028',
      );
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
      }, 5000);

      return false;
    } else {
      setError(null);
      handleSubmit(goToNextForm)();
    }
    return true;
  };

  const getCPFDetails = async (cpf: string, matricula: string) => {
    const formatedCpf = cpf.replace(/[.\-\s]/g, '');
    const formatedMatricula = matricula.replace(/[.\-\s]/g, '');
    const getAssociado = await fetch(
      `${process.env.REACT_APP_OG_GET_ASSOCIADO_CPF}?cpf=${formatedCpf}
      &matricula=${formatedMatricula}`,
    ).then(response => response.json());
    if (getAssociado.cpf) {
      setValue('valida.validation.retorno', getAssociado);
    }
  };

  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Dados
      </h2>

      <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
        <Input
          label="CPF"
          placeholder="CPF"
          activateMask
          mask="999.999.999-99"
          errorMessage={errors.valida?.validation?.cpf?.message}
          {...register('valida.validation.cpf')}
        />
        <Input
          label="matricula"
          placeholder="matricula"
          errorMessage={errors.valida?.validation?.matricula?.message}
          {...register('valida.validation.matricula')}
        />
      </div>

      {showError && (
        <div className="fixed top-24 left-1/2 transform -translate-x-1/5 bg-red-500 text-white py-2 px-4 rounded shadow-md">
          <p className="text-sm font-medium">
            {error?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      )}

      <GoForwardButton onForwardClick={validaDados} />
    </form>
  );
}
