import React, { ReactElement, useEffect, useState } from 'react';
import { GoForwardButton } from '../GoForwardButton';
import FunctionaryForm from './functionary/functionaryForm';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHireBussinessPlanForm } from '../../../contexts/HirePlanBussinessFormProvider';
import {
  useFunctionaryAddDataStore,
  useFunctionaryExcelDataStore,
  useFunctionaryIdStore,
  useFunctionaryOpenFormDataStore,
  useFunctionaryOpenModalDataStore,
} from '../../../stores/useFunctionaryStore';
import { useVidaCountStore } from '../../../stores/useQuantidadeVidas';
import { useRouteLoaderData } from 'react-router-dom';
import { changeGender } from '../../../utils/ChangeArrays';

export function FunctionaryRegisterForm(): ReactElement {
  const {
    register,
    handleSubmit,
    goToNextForm,
    errors,
    watch,
    setValue,
    setFuncValue,
  } = useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const setFuncId = useFunctionaryIdStore(state => state.setId);
  const FuncId = useFunctionaryIdStore(state => state.id);
  const [funcionarioAdicionado, setfuncionarioAdicionado] = useState(false);
  const funcionarios =
    watch('user.funcionarios') != undefined ? watch('user.funcionarios') : [];
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const toggleExpandRow = (index: number) => {
    setExpandedRow(prevIndex => (prevIndex === index ? null : index));
  };
  const setCountVidas = useVidaCountStore(x => x.setVidaCount);
  const Vidas = useVidaCountStore(x => x.count);

  const setFuncionaryOpenModal = useFunctionaryOpenModalDataStore(
    x => x.setfunctionaryOpenModalData,
  );
  const setOpenForm = useFunctionaryOpenFormDataStore(
    x => x.setfunctionaryOpenFormData,
  );
  const setAddFuncionary = useFunctionaryAddDataStore(
    x => x.setfunctionaryAddData,
  );
  const setAddExcel = useFunctionaryExcelDataStore(
    x => x.setfunctionaryExcelData,
  );

  useEffect(() => {
    const currentFuncionarios = watch('user.funcionarios') || [];
    let countFunc = 0;
    if (currentFuncionarios) {
      currentFuncionarios.forEach((funcionario, index) => {
        if (funcionario.cpf == undefined || funcionario.cpf == '') {
          removeClick(index);
        } else {
          countFunc = countFunc + 1;
        }
      });
    }
    const countdependentes = currentFuncionarios.reduce(
      (count, funcionario) => {
        return (
          count + (funcionario.dependentes ? funcionario.dependentes.length : 0)
        );
      },
      0,
    );
    const count = countFunc + countdependentes;
    setCountVidas(count);
  }, [funcionarios]);

  const editClick = (index: number) => {
    setFuncId(index);
    setFuncionaryOpenModal(true);
    setOpenForm(true);
    setAddFuncionary(false);
    setAddExcel(false);
  };
  const [error, setError] = useState<string | null>(null);
  const removeClick = (ind: number) => {
    const currentFuncionarios = watch('user.funcionarios') || [];
    const updatedFuncionarios = currentFuncionarios.filter(
      (_, index) => index !== ind,
    );
    setValue('user.funcionarios', updatedFuncionarios);
  };
  changeGender(initialPlanData.listaSexos);

  useEffect(() => {
    if (
      (funcionarios &&
        funcionarios[0] &&
        funcionarios[0].nome != undefined &&
        funcionarios[0].nome != '') ||
      Vidas > 0
    ) {
      setfuncionarioAdicionado(true);
    } else {
      removeClick(0);
      setfuncionarioAdicionado(false);
    }
  }, [funcionarios, Vidas]);
  const validaQTD = () => {
    if (
      Vidas >= initialPlanData.categoria.nu_vidas_min &&
      Vidas < initialPlanData.categoria.nu_vidas_max
    ) {
      const funcionarios = watch('user.funcionarios');
      const functionaryQuantity = watch('user.functionaryQuantity');
      if (funcionarios.length > parseFloat(functionaryQuantity)) {
        setError(
          'Quantidade de funcionários deve ser menor ou igual a quantidade informada',
        );
        return false;
      } else {
        setError(null);
        handleSubmit(goToNextForm)();
        return true;
      }
    } else {
      setError(
        'Quantidade de vidas deve estar obrigatóriamente entre (' +
          initialPlanData.categoria.nu_vidas_min +
          ' e ' +
          initialPlanData.categoria.nu_vidas_max +
          ')',
      );
      return false;
    }
  };
  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Funcionários
      </h2>
      {funcionarioAdicionado && (
        <table width="100%">
          <thead style={{ background: '#272D65', color: '#FFFFFF' }}>
            <tr>
              <th></th>
              <th style={{ textAlign: 'center' }}>Nome</th>
              <th style={{ textAlign: 'center' }}>CPF</th>
              <th style={{ textAlign: 'center' }}>Data de Nascimento</th>
              <th style={{ textAlign: 'center' }}>Sexo</th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {funcionarios.map((funcionario, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => toggleExpandRow(index)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <td>
                    {expandedRow === index ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <ArrowForwardIosIcon />
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>{funcionario.nome}</td>
                  <td style={{ textAlign: 'center' }}>{funcionario.cpf}</td>
                  <td style={{ textAlign: 'center' }}>
                    {funcionario.dtNascimento}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {funcionario.sexo != '' && funcionario.sexo != undefined
                      ? initialPlanData.listaSexos.find(
                          x => x.Id == funcionario.sexo,
                        ).Nome
                      : ''}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <button type="button" onClick={() => editClick(index)}>
                      Editar
                    </button>
                    {' / '}
                    <button type="button" onClick={() => removeClick(index)}>
                      Remover
                    </button>
                  </td>
                </tr>
                {expandedRow === index && funcionario.dependentes && (
                  <tr>
                    <td colSpan={6}>
                      <strong style={{ margin: '2%' }}>Dependentes:</strong>
                      <table width="96%" style={{ margin: '2%' }}>
                        <thead
                          style={{ background: '#DDE1FF', color: '#272D65' }}
                        >
                          <tr>
                            <th style={{ textAlign: 'center' }}>Nome</th>
                            <th style={{ textAlign: 'center' }}>CPF</th>
                            <th style={{ textAlign: 'center' }}>
                              Data de Nascimento
                            </th>
                            <th style={{ textAlign: 'center' }}>Parentesco</th>
                          </tr>
                        </thead>
                        <tbody>
                          {funcionario.dependentes.map(
                            (dependente, depIndex) => (
                              <React.Fragment key={depIndex}>
                                <tr>
                                  <td style={{ textAlign: 'center' }}>
                                    {dependente.nome}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {dependente.cpf}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {dependente.dtNascimento}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {dependente.parentesco
                                      ? initialPlanData.listaParentescos.find(
                                          x => x.Id == dependente.parentesco,
                                        )?.Sigla
                                      : ''}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ),
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
      <div style={{ marginBottom: '20px' }}>
        <FunctionaryForm />
      </div>
      <GoForwardButton addBackButton validate={validaQTD} tipo="button" />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
}

export default FunctionaryRegisterForm;
